import React from 'react';
import { List, ListItem, ListItemText, Box, Paper, Typography,Icon } from "@mui/material/";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';

const AboutPage =() => {
    const purpose = [
        'Bring together families and children with a common interest in the sport of badminton.',
        'Encourage, promote and develop skills for playing badminton',
        'Develop the members ability and desire to get involved and take joint responsibility in running the club.',
        'Provide a platform for social interaction through the sport of badminton.',
    ]
    const GeneratePurpose = () => {
        return (
            <List dense={true}>
                {purpose.map (n =>
                <ListItem>
                  <ListItemText
                    primary={n}
                  />
                </ListItem>)}
            </List> );
    }
    return (
        <Paper>
            <RenderDashboardHeader maintext="Every Move Counts" subtext="Badminton club" ></RenderDashboardHeader>
            <Typography variant="h5" gutterBottom component="div">
                About EMC Badminton Club
            </Typography>
            <Typography variant="body1" gutterBottom>
                Every Move Counts (EMC) Badminton club was established in the year 2021 and registered in Brøndby kommune 
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
                Purpose
            </Typography>
            <GeneratePurpose/>
            <Typography variant="h6" gutterBottom component="div">
                Feedback
            </Typography>
            <Typography variant="body1" gutterBottom>
                Feedback can be provided here <a href='https://docs.google.com/spreadsheets/d/1gFSTL0WglzsrEF5H1C7PpHL30Dcm3LQu8FOLeZ0_PTY/edit?usp=sharing' target='_blank'>link to feedback sheet </a>
            </Typography>
        </Paper>
    );
}
export default AboutPage;
