import React from 'react';
import { useState, useEffect } from 'react';
import {getEvents,getUsersLean} from '../services/api';
import AllEvents from '../components/AllEvents';
import { RenderDashboardHeader, WaitForData } from '../components/EMCRenderUtils';

const EMCBTrainingPage=() =>{

  const [events,setEvents] = useState([]);
  const [loading,setLoading] = useState(true);
  const [members,setMembers] = useState([]);
  useEffect( () => {
    getEvents()
        .then (t=> { setEvents(t); setLoading(false) });
  },[]);
    useEffect( () => {
      getUsersLean()
          .then (t=>  setMembers(t));
    },[]);

    return (
      <>
      <RenderDashboardHeader maintext="EMC Badminton Practice" />
      <AllEvents Events={events} members={members} /> 
      <WaitForData show={loading}/>
      </>
    );
}

export default EMCBTrainingPage;