import {Grid, Paper} from '@mui/material';
import React from 'react';
import { EventCard } from './EventCard.js';


const AllEvents = ({Events,members }) => {
    return(
      <Grid  justifyContent="center" alignItems="center" spacing={0.25} container>
      {Events.map(event =>  
          <Grid item xs={12} md={6} key={event.id}> 
            <EventCard item={event} members={members}/>
          </Grid>
            )}
      </Grid>
    );
}

export default AllEvents;