import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../App';
import {Stack,Card, CardHeader, Badge,  IconButton, CardActions, CardContent, Box,Grid, Chip,  Divider, Button } from '@mui/material/';
import { signUpForEvent, signOutFromEvent, getEvent } from '../services/api';
import moment from 'moment';
import MapIcon from '../img/167px-Google_Maps_icon.png';
import { WaitForData } from './EMCRenderUtils';


export const EventCard = ({ item, members }) => {
    const showouts = false;
    const Signup = async () => {
         setSignup(true); 
         signUpForEvent(id, loggedinUserID)
          .then (t=> getEvent(id).then(item => { setEvent(item); setSignup(false); }) );
   }
    const Signout = async () => {
        setSignout(true);
         signOutFromEvent(id, loggedinUserID)
          .then (t=> getEvent(id).then(item => { setEvent(item); setSignout(false); }));
   }
   const RenderButtons = () => {
    if (!(participants.includes(loggedinUserID)))
      return <Button size="small" variant="contained" color="success" onClick={()=> Signup()}> Sign up </Button> ; 
    else if (counts[loggedinUserID] != null && counts[loggedinUserID] === 1)
      return <><Button size="small" variant="contained" color="success" onClick={()=> Signup()}> +1 </Button>  <Button size="small" variant="contained" color="warning" onClick={()=>Signout()}>Sign out</Button></>
    else if (counts[loggedinUserID] != null && counts[loggedinUserID] > 1)
      return <><Button size="small" variant="contained" color="success" onClick={()=> Signup()}> +1 </Button>  <Button size="small" variant="contained" color="warning" onClick={()=>Signout()}> -1 </Button></>
    else
      return <></>;
   }
    const {state}  = useContext(AuthContext);
    const loggedinUserID = state.user;
    const [signup, setSignup]=  useState(false);
    const [event, setEvent] = useState(item);
    const [signout, setSignout]=  useState(false);
    const [participatingmembers, setParticipatingmembers] = useState([]);
    const [counts, setCount] = useState({});
    const [nonparticipatingmembers, setNonparticipatingmembers] = useState([]);
    var { id, location, address, participants, status } = event ;
    var yesUsers = [];
    var noUsers = [] ;
    useEffect( () => {
      let countedIds = participants.reduce((allNames, name) => {
                            if (name in allNames) {
                              allNames[name]++
                            }
                            else {
                              allNames[name] = 1
                            }
                            return allNames
                          }, {})
        members.forEach((mem) =>
          { (participants.indexOf(mem.id.toString()) === -1) ? noUsers.push(mem) : yesUsers.push(mem) }
        ) ;
        noUsers.sort((a, b) => a.firstname.localeCompare(b.firstname));
        yesUsers.sort((a, b) => a.firstname.localeCompare(b.firstname));
        setParticipatingmembers(yesUsers);
        setCount(countedIds);
        setNonparticipatingmembers(noUsers); }, [event,members]);
    let bgcolor = (status === 'cancelled')? '#fdd' : '#fff' ;
    return (
      <Box sx={{width:'98%', border:'0px solid blue' ,bgcolor: 'text.disabled' }}>
         <Card sx={{width:'100%', bgcolor: bgcolor }}>
          <CardHeader
            //action= {(participants.indexOf(loggedinUserID) === -1 ? <Button variant="contained" color="success" onClick={()=> Signup()}> Signup </Button> : <Button variant="contained" color="warning" onClick={()=>Signout()}>Signout</Button>)}
            //action= {<><Button variant="contained" color="success" onClick={()=> Signup()}> Signup </Button>  <Button variant="contained" color="warning" onClick={()=>Signout()}>Signout</Button></>}
            action= { <IconButton onClick={()=> window.open("https://www.google.com/maps/search/?api=1&query="+encodeURI(address), "_blank") } aria-label="open map">
              <img src={MapIcon} height={35} width={20} alt='location map' />
            </IconButton> }
            title={location}
            subheader={new Date(moment(id,"YYYYMMDDHHmm")).toLocaleString('en-GB',{ dateStyle: 'full', timeStyle: 'short' })}
          />
           <CardActions disableSpacing>
            <Stack direction="row" spacing={1}>
            <RenderButtons/>
            </Stack>
          </CardActions>
            <CardContent>
              <Box color={'green.600'} fontSize={'0.875rem'} >
                  <Grid container spacing={2}>
                { participatingmembers.map((user) => { 
                          let rpt = counts[user.id]
                          rpt--;
                          return ( <Grid item xs={6} >
                              <Badge color="secondary" badgeContent={rpt}>
                                <Chip
                                    label={user.name}
                                    key={user.id}
                                    size="small"
                                    color="success"
                                    variant="filled"
                                  />
                                </Badge>                          
                            </Grid>
                          )  }
                        ) 
                          
                  }
                  </Grid>
             </Box>
             {showouts ?
             <Box color={'red.600'} fontSize={'0.875rem'} >
                <Divider sx={{my:2}}/>
                  <Grid container spacing={1}>
                { nonparticipatingmembers.map((user) =>  
                          ( <Grid item xs={4} >
                              <Chip
                                  label={user.name}
                                  key={user.id}
                                  size="small"
                                  color="warning"
                                  variant="filled"
                                />
                            </Grid>
                            )) 
                  }
                  </Grid>
             </Box> : <></> }
            </CardContent>
          </Card>
          <WaitForData show={signup||signout}/>
      </Box>
    );
  };

  