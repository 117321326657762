import React from 'react';
import {TableContainer, TableHead, List, ListItem, ListItemText, Table, TableRow,TableCell, TableBody, Container, Box, Paper, Typography } from "@mui/material/";
import { RenderDashboardHeader } from '../components/EMCRenderUtils';

const HomePage =() => {

    const news = [
        'Mobile friendly design',
        'Install as app on iOS, Android and PC',
        'Weekly training signup in/out.',
    ]

    const GenerateNewsList = () => {
        return (
            <List dense={true}>
                {news.map (n =>
                <ListItem>
                  <ListItemText
                    primary={n}
                  />
                </ListItem>)}
            </List> );
    }
    return (
        <Paper>
            <RenderDashboardHeader maintext="Every Move Counts" subtext="Badminton club" ></RenderDashboardHeader>
            <Typography variant="h5" gutterBottom component="div">
                Welcome to the EMC Badminton App
            </Typography>
            <Typography variant="body1" gutterBottom>
                The EMC badminton app/website will allow installation to both Android and iOS using a progressive web app framework.
                For more instructions on how to use the app and install the app to your phone/device Please follow the <a href='https://www.youtube.com/playlist?list=PLvaeISnroU_pv_4xSySgtImLGxaM659VJ' target='_blank'>Video Guides </a>
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
                Whats New?
            </Typography>
            <GenerateNewsList/>
            <Typography variant="h6" gutterBottom component="div">
                Feedback
            </Typography>
            <Typography variant="body1" gutterBottom>
                Feedback can be provided here <a href='https://docs.google.com/spreadsheets/d/1gFSTL0WglzsrEF5H1C7PpHL30Dcm3LQu8FOLeZ0_PTY/edit?usp=sharing' target='_blank'>link to feedback sheet </a>
            </Typography>
        </Paper>
    );
}
export default HomePage;
