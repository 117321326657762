import { augmentLeanUsers } from "../components/EMCUtils";

//const baseurl='https://fe0wnf6h3d.execute-api.eu-west-1.amazonaws.com/test'
//const baseurl='https://uchsh7vp1k.execute-api.eu-west-1.amazonaws.com/test'
const baseurl='https://api.badminton.everymovecounts.dk/api'
export const getEvents = async () => {
  const data = await fetch(baseurl + '/training/future');
  const data_1 = await data.json();
  return data_1.Items;
}

export const getUsersLean = async () => {
  const data = await fetch(baseurl + '/players');
  const data_1 = await data.json();
  return augmentLeanUsers(data_1.Items);
}

export const getEvent = async (id) => {
  const data = await fetch(baseurl + '/training/' + id);
  const data_1 = await data.json();
  return data_1.Items[0];
}
export const signUpForEvent = async (eventid,name) => {
  const data = await fetch(baseurl + '/training/' + eventid + '/signup/'+name);
  return await data.json();
}

export const signOutFromEvent = async (eventid,name) => {
  const data = await fetch(baseurl + '/training/' + eventid + '/signout/'+name);
  return await data.json();
}

